import React from "react";

const Footer = () => {
  return (
    <footer className="my-5 pt-5 text-muted text-center text-small">
      <p className="mb-1">&copy; 2019 Total Recall Learning</p>
      <ul className="list-inline">
        <li className="list-inline-item">
          <a href="mailto:falbo@alliant.edu">Support</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
