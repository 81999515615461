import http from "./httpService";

const apiEndPoint = "/courses";

export function setSessionChapters(chapters) {
  localStorage.setItem("chapters", chapters);
}

export function setSessionCourse(courseId) {
  localStorage.setItem("course", courseId);
}
export function getSessionCourse() {
  return localStorage.getItem("course");
}
export function getSessionChapters() {
  return localStorage
    .getItem("chapters")
    .split(",")
    .map(Number);
  return;
}

export function getSessionQuestion() {
  return http.post(apiEndPoint + "/getQuestion", {
    courseId: getSessionCourse(),
    chapters: getSessionChapters()
  });
}
export function answerSessionQuestion(questionId, level, answer) {
  const requestBody = {
    courseId: getSessionCourse(),
    questionId: questionId,
    level: level,
    answer: answer
  };
  return http.post(apiEndPoint + "/answerQuestion", requestBody);
}
