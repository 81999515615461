import React from "react";
import { Link } from "react-router-dom";
const NavBar = ({ user }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <h3>
          Recall<strong>Cloud</strong>
          <span
            className="badge badge-pill badge-secondary m-2"
            style={{ fontSize: 10 }}
          >
            DEMO
          </span>
        </h3>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav mr-auto">
          <Link className="nav-item nav-link active" to="/courses">
            Courses <span className="sr-only">(current)</span>
          </Link>
          {user && (
            <Link className="nav-item nav-link" to="/my-courses">
              My Courses
            </Link>
          )}
        </div>
        <div className="navbar-nav">
          {!user && (
            <React.Fragment>
              <Link className="nav-item nav-link" to="/sign-up">
                Sign Up
              </Link>
              <Link className="nav-item nav-link" to="/log-in">
                Login
              </Link>
            </React.Fragment>
          )}
          {user && (
            <Link className="nav-item nav-link" to="/log-out">
              Logout
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
