import React, { Component } from "react";
import { getCourseChapters } from "../services/coursesService";
import { Link, Redirect } from "react-router-dom";
import auth from "../services/authService";
import {
  setSessionChapters,
  setSessionCourse
} from "../services/sessionService";
class Briefing extends Component {
  state = {
    course: {},
    chapters: [],
    chaptersSelected: []
  };
  async componentDidMount() {
    // Get course chapters
    const courseId = this.props.match.params.id;
    const { data: courseData } = await getCourseChapters(courseId);
    const chapters = courseData.chapters;
    let chaptersSelected = [];
    for (let chapter of chapters) chaptersSelected.push(chapter.chapterID);

    this.setState({ course: courseData, chapters, chaptersSelected });
  }

  validate = () => {
    return !this.state.chaptersSelected.length > 0;
  };
  handleCheck = e => {
    const { checked, value } = e.target;
    let chaptersSelected;
    if (!checked) {
      // Remove chapter
      chaptersSelected = this.state.chaptersSelected.filter(
        chapter => chapter !== Number(value)
      );
    } else {
      chaptersSelected = [...this.state.chaptersSelected];
      chaptersSelected.push(Number(value));
    }
    this.setState({ chaptersSelected });
  };
  handleSubmit = e => {
    e.preventDefault();
    // Store course & chapters
    const courseId = this.props.match.params.id;
    const { chaptersSelected: chapters } = this.state;
    setSessionCourse(courseId);
    setSessionChapters(chapters);
    //    localStorage.setItem("chapters", chapters);
    window.location = "/get-question";
  };

  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/" />;

    const { course, chapters } = this.state;

    return (
      <div className="container" style={{ paddingTop: 20 }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/my-courses">My Courses</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {course.courseName}
            </li>
          </ol>
        </nav>
        <div className="d-flex align-items-center p-3 my-3 text-secondary-50 bg-light rounded shadow-sm">
          <div className="lh-100">
            <h4>{course.courseName}</h4>
            <small>Available Chapters</small>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col justify-content-md-center">
              <ul
                className="list-unstyled justify-content-md-center"
                style={{ paddingLeft: "4rem" }}
              >
                {chapters.map(chapter => (
                  <li key={chapter.chapterID}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={this.handleCheck}
                        key={chapter.chapterID}
                        defaultChecked={true}
                        value={chapter.chapterID}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        {chapter.chapterName}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <form onSubmit={this.handleSubmit}>
                <button
                  disabled={this.validate()}
                  to="#"
                  className="btn btn-primary btn-sm"
                >
                  Start Learning
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Briefing;
