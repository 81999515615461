import React, { Component } from "react";
import { getMyCourses } from "../services/userService";
import { getCourses, addCourse } from "../services/coursesService";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Courses extends Component {
  state = { courses: [], myCourses: [] };

  handleClick = async courseId => {
    const prevState = this.state.myCourses;
    // Add to array
    const myCourses = [...this.state.myCourses, courseId];
    this.setState({ myCourses });

    try {
      addCourse(courseId);
    } catch (ex) {
      // Reset changes
      this.setState({ myCourses: prevState });
      return toast.error("An unexpected error occurred.");
    }
  };
  async componentDidMount() {
    // Get courses
    const { data: courses } = await getCourses();
    this.setState({ courses });

    if (this.props.user) {
      const { data: courses } = await getMyCourses();
      const myCourses = [];
      for (let course of courses) myCourses.push(course._id);
      console.log(myCourses);
      this.setState({ myCourses });
    }
  }
  render() {
    const { courses, myCourses } = this.state;
    const { user } = this.props;
    return (
      <div className="container" style={{ paddingTop: 20 }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Courses
            </li>
          </ol>
        </nav>
        <h4>Courses</h4>
        <div className="row">
          {courses.map(course => (
            <div className="col-sm-3 text-center p-3" key={course._id}>
              <div className="card">
                <Link to={`course/${course._id}`}>
                  {course.courseImage == "/" ? (
                    <img
                      src="https://picsum.photos/300?&random"
                      className="card-img-top"
                      alt="..."
                    />
                  ) : (
                    <img
                      src={course.courseImage}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                </Link>
                <div className="card-body">
                  <Link to={`course/${course._id}`}>
                    <h5 className="card-title">{course.courseName}</h5>
                  </Link>
                  {user &&
                    (myCourses.includes(course._id) ? (
                      <Link to="#" className="btn btn-success btn-sm disabled">
                        In my courses
                        <i className="fa fa-check" aria-hidden="true" />
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        onClick={courseId => this.handleClick(course._id)}
                        className="btn btn-primary btn-sm"
                      >
                        Add to my courses{" "}
                        <i className="fa fa-plus" aria-hidden="true" />
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Courses;
