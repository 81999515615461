import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavBar from "./components/navBar";
import Footer from "./components/footer";
import Courses from "./components/courses";
import MyCourses from "./components/myCourses";
import SignUp from "./components/signUp";
import LogIn from "./components/logIn";
import LogOut from "./components/logOut";
import Profile from "./components/profile";
import NotFound from "./components/notFound";
import auth from "./services/authService";
import Course from "./components/course";
import Question from "./components/question";
import Briefing from "./components/briefing";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

class App extends Component {
  state = { home: Courses };

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
    if (user) this.setState({ home: MyCourses });
  }
  render() {
    const { user, home } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} />
        <main className="container">
          <Switch>
            <Route
              path="/courses"
              render={props => <Courses {...props} user={user} />}
            />
            <Route path="/briefing/:id" component={Briefing} />
            <Route path="/course/:id" component={Course} />

            <Route
              path="/my-courses"
              render={props => <MyCourses {...props} user={user} />}
            />
            <Route
              path="/get-question"
              render={props => <Question {...props} user={user} />}
            />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/log-in" component={LogIn} />
            <Route path="/log-out" component={LogOut} />
            <Route path="/profile" component={Profile} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact component={home} />
            <Redirect to="/not-found" />
          </Switch>
        </main>

        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
