import React, { Component } from "react";
import {
  getSessionQuestion,
  answerSessionQuestion
} from "../services/sessionService";
import { toast } from "react-toastify";
import Input from "./common/input";

class Question extends Component {
  state = {
    question: {},
    answer: "",
    correctAnswer: {},
    showAnswer: false,
    audioCompatible: navigator.userAgent.indexOf("Chrome") != -1,
    answerValue: "",
    questionLanguage: "en-US",
    totalQuestions: 0,
    totalCorrect: 0,
    isRecording: false
  };
  componentDidMount() {
    this.getQuestion();
  }
  voiceAnswer = async () => {
    // Experimental
    this.setState({ isRecording: true });
    let recognition = new window.webkitSpeechRecognition();
    recognition.lang = this.state.questionLanguage;
    recognition.start();

    recognition.onresult = async (event, that) => {
      const last = event.results.length - 1;
      let answerValue = event.results[last][0].transcript;
      await this.setState({ answer: answerValue, answerValue });
    };
    recognition.onend = () => {
      this.setState({ isRecording: false });
    };
  };
  playAudio = async () => {
    // Experimental
    let textQuestion = this.state.question.question;

    textQuestion = textQuestion.replace("_", this.state.correctAnswer.answer);
    textQuestion = textQuestion.replace(/_/gi, "");

    const message = new SpeechSynthesisUtterance(textQuestion);
    let voices;
    const language = this.state.questionLanguage;
    let interval = setInterval(function() {
      voices = speechSynthesis.getVoices();
      if (voices.length) {
        clearInterval(interval);
        message.rate = 0.9;
        let voice = 48;

        if (language === "es-US") voice = 52;
        else if (language === "zh-CN") voice = 63;

        message.voice = voices[voice];
        speechSynthesis.speak(message);
      } else return;
    });
  };
  getQuestion = async () => {
    try {
      const { data: question } = await getSessionQuestion();
      const showAnswer = false;
      const answer = "";
      const questionLanguage = question.courseLanguage;
      this.setState({
        question,
        showAnswer,
        answer,
        questionLanguage
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data.message;
        this.setState({ errors });
      } else {
        return toast.error("An unexpected error occurred.");
      }
    }
  };
  answerQuestion = async () => {
    const { question, answer } = this.state;
    try {
      const { data: correctAnswer } = await answerSessionQuestion(
        question._id,
        question.level,
        answer.trim()
      );
      const showAnswer = true;
      const answerValue = "";

      this.setState({
        correctAnswer,
        showAnswer,
        answerValue,
        totalCorrect:
          correctAnswer.result !== 0
            ? ++this.state.totalCorrect
            : this.state.totalCorrect,
        totalQuestions: this.state.totalQuestions + 1
      });
    } catch (ex) {
      return toast.error("An unexpected error occurred.");
    }
  };
  returnLevel(level) {
    if (level === "new") return "New Question";
    if (level === "level1") return "Level 1";
    if (level === "level2") return "Level 2";
    if (level === "level3") return "Level 3";
    if (level === "level4") return "Level 4";
    if (level === "level5") return "Level 5";
    if (level === "missed") return "Missed";
  }
  handleChange = ({ currentTarget: input }) => {
    const answer = input.value;
    const answerValue = answer;
    this.setState({ answer, answerValue });
  };
  handleKeyPress = e => {
    console.log("Pressed");
    if (e.key == "Enter") {
      if (this.state.showAnswer) return this.getQuestion();
      return this.answerQuestion();
    }
  };
  render() {
    const {
      question,
      correctAnswer,
      showAnswer,
      answer,
      audioCompatible,
      answerValue,
      totalQuestions,
      totalCorrect,
      isRecording
    } = this.state;
    question.questionImage =
      question.questionImage === "/"
        ? "https://picsum.photos/400?random=1"
        : question.questionImage;
    return (
      <div className="container" style={{ paddingTop: 20 }}>
        <div className="row ">
          <div className="col-md-5 d-none d-sm-block ">
            <img src={question.questionImage} width="400" alt="" />
            <div class="row p-4">
              <div class="col">Total Questions: {totalQuestions}</div>
              <div class="col">Correct: {totalCorrect}</div>
            </div>
          </div>

          <div className="col-md-7">
            <p>⭐ {this.returnLevel(question.level)}</p>
            <p>📚 {question.chapterName}</p>
            <p style={{ fontSize: 24 }}>
              ☝️
              {question.question}
            </p>
            {question.questionAddText && ( // Additional text
              <p style={{ fontSize: 12 }}>ℹ️ {question.questionAddText}</p>
            )}
            {showAnswer &&
            correctAnswer.result !== 0 && ( // Correct Answer
                <p className="text-success">✅ Correct!</p>
              )}
            {showAnswer &&
            correctAnswer.result === 2 && ( // Prefer Answer
                <p>
                  ℹ️ Prefered answer:{" "}
                  <span className="text-success font-weight-bold">
                    {correctAnswer.answer}
                  </span>
                </p>
              )}
            {!showAnswer && (
              <div style={{ fontSize: 12 }}>
                Answer
                <Input
                  autoFocus
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  value={answerValue}
                />
              </div>
            )}
            {showAnswer && correctAnswer.result === 0 && (
              <div>
                <p className="text-danger" style={{ fontSize: 18 }}>
                  ❌ {answer}
                </p>
                <p>
                  ️️✅ Correct answer:{" "}
                  <span className="text-success font-weight-bold">
                    {correctAnswer.answer}
                  </span>
                </p>
                {correctAnswer.answerText && (
                  <p style={{ fontSize: 12 }}>ℹ️ {correctAnswer.answerText}</p>
                )}
              </div>
            )}
            <p>
              {showAnswer && (
                <div>
                  <button
                    className="btn btn-primary"
                    autoFocus
                    onClick={this.getQuestion}
                  >
                    Next Question
                  </button>{" "}
                  {audioCompatible && (
                    <button
                      className="btn btn-light border border-secondary"
                      onClick={this.playAudio}
                    >
                      🔈
                    </button>
                  )}
                </div>
              )}
              {!showAnswer && (
                <div>
                  <button
                    className="btn btn-primary"
                    onKeyPress={this.handleKeyPress}
                    onClick={this.answerQuestion}
                  >
                    Answer
                  </button>{" "}
                  {audioCompatible && !isRecording && (
                    <button
                      className="btn btn-light border border-secondary"
                      onClick={this.voiceAnswer}
                    >
                      🎤
                    </button>
                  )}
                  {audioCompatible && isRecording && (
                    <button
                      className="btn btn-light border border-secondary recordingInsession"
                      onClick={this.voiceAnswer}
                    >
                      🎤
                    </button>
                  )}
                </div>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Question;
