import http from "./httpService";

const apiEndpoint = "/courses";

export function getCourses() {
  return http.get(apiEndpoint);
}
export function addCourse(courseId) {
  return http.put(apiEndpoint + "/add/" + courseId);
}

export function getCourseChapters(courseId) {
  return http.get(`${apiEndpoint}/${courseId}`);
}
