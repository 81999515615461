import React, { Component } from "react";
import { getCourses, getCourseChapters } from "../services/coursesService";
import { Link } from "react-router-dom";

class Course extends Component {
  state = {
    course: {},
    chapters: []
  };
  async componentDidMount() {
    // Get course chapters
    const courseId = this.props.match.params.id;
    const { data: courseData } = await getCourseChapters(courseId);
    const chapters = courseData.chapters;
    this.setState({ course: courseData, chapters });
  }
  render() {
    const { course, chapters } = this.state;

    return (
      <div className="container" style={{ paddingTop: 20 }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/courses">Courses</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {course.courseName}
            </li>
          </ol>
        </nav>
        <h4>{course.courseName}</h4>
        <p>{course.courseDescription}</p>
        <ul>
          {chapters.map(chapter => (
            <li key={chapter.chapterID}>
              <span className="badge badge-secondary">
                Chapter {chapter.chapterID}: {chapter.chapterName}
              </span>
              <ul>
                <li className="font-italic">{chapter.chapterDesc}</li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Course;
