import React, { Component } from "react";
import { getMyCourses } from "../services/userService";
import { getCourses, addCourse } from "../services/coursesService";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../services/authService";

class MyCourses extends Component {
  state = { courses: [] };

  async componentDidMount() {
    const { data: courses } = await getMyCourses();
    this.setState({ courses });
  }
  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/" />;
    const { courses } = this.state;
    const { user } = this.props;
    return (
      <div className="container" style={{ paddingTop: 20 }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item " aria-current="page">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Courses
            </li>
          </ol>
        </nav>
        <h4>My Courses</h4>
        <div className="row">
          {courses.map(course => (
            <div className="col-sm-3 text-center" key={course._id}>
              <div className="card">
                <Link to={`briefing/${course._id}`}>
                  {course.courseImage == "/" ? (
                    <img
                      src="https://picsum.photos/300?&random"
                      className="card-img-top"
                      alt="..."
                    />
                  ) : (
                    <img
                      src={course.courseImage}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                </Link>
                <div className="card-body">
                  <Link to={`briefing/${course._id}`}>
                    <h5 className="card-title">{course.courseName}</h5>
                  </Link>
                  <Link
                    to={`briefing/${course._id}`}
                    className="btn btn-primary btn-sm"
                  >
                    Start
                    <i className="fa fa-check" aria-hidden="true" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MyCourses;
