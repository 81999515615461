import http from "./httpService";
import jwtDecode from "jwt-decode";

http.setJwt(getJwt());
const tokenKey = "token";

const apiEndpoint = "/user";

export function getMyCourses() {
  return http.get(apiEndpoint + "/courses");
}
export function getJwt() {
  return localStorage.getItem(tokenKey);
}
export default {
  getMyCourses
};
